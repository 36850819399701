import { MutationHookOptions } from '@apollo/client'

import {
  useUpdateTicketOrderMutation,
  Enum_Ticketorders_Fulfillment_Status as FulfillmentStatus,
  Enum_Componentticketsfulfillmentstatushistory_Status_Name,
  UpdateTicketOrderMutation,
  ComponentTicketsFulfillmentStatusHistoryInput,
  TicketOrderFieldsFragment,
  Enum_Ticketorders_Fulfillment_Status,
} from '~graphql/generated'
import { useUser } from '~context/user'

export function useResetOrder(
  id: string,
  options?: MutationHookOptions<UpdateTicketOrderMutation>
) {
  return useUpdateTicketOrderMutation({
    ...options,
    variables: {
      id,
      data: {
        fulfillment_status: FulfillmentStatus.Pending,
        fulfillment_status_history: [],
      },
    },
  })
}

export function useUpdateOrderStatus(
  order: TicketOrderFieldsFragment,
  fulfillmentStatus: Enum_Ticketorders_Fulfillment_Status,
  historyStatus: Enum_Componentticketsfulfillmentstatushistory_Status_Name,
  options?: MutationHookOptions<UpdateTicketOrderMutation>
) {
  const { user } = useUser()

  return useUpdateTicketOrderMutation({
    ...options,
    variables: {
      id: order.id,
      data: {
        fulfillment_status: fulfillmentStatus,
        fulfillment_status_history: constructUpdatedFulfillmentHistory(
          order,
          historyStatus,
          user.id.toString()
        ),
      },
    },
  })
}

export function useUpdateFulfillmentHistory(
  order: TicketOrderFieldsFragment,
  status: Enum_Componentticketsfulfillmentstatushistory_Status_Name
) {
  const { user } = useUser()

  return useUpdateTicketOrderMutation({
    variables: {
      id: order.id,
      data: {
        fulfillment_status_history: constructUpdatedFulfillmentHistory(
          order,
          status,
          user.id.toString()
        ),
      },
    },
  })
}

export function constructUpdatedFulfillmentHistory(
  order: TicketOrderFieldsFragment,
  status: Enum_Componentticketsfulfillmentstatushistory_Status_Name,
  triggeredByUserId: string
): ComponentTicketsFulfillmentStatusHistoryInput[] {
  const currentHistory = order.fulfillment_status_history
    ? order.fulfillment_status_history.map(
        (history): ComponentTicketsFulfillmentStatusHistoryInput => ({
          status_name: history?.status_name,
          triggered_by: history?.triggered_by?.id,
        })
      )
    : []

  const fulfillment_status_history = [
    ...currentHistory,
    {
      status_name: status,
      triggered_by: triggeredByUserId,
    },
  ]

  return fulfillment_status_history
}

// export function useConfirmOrder(
//     id: string,
//     updatedFulfillmentHistory: ComponentTicketsFulfillmentStatusHistoryInput[],
//     options?: MutationHookOptions<UpdateTicketOrderMutation>
//   ) {
//     return useUpdateTicketOrderMutation({
//       ...options,
//       variables: {
//         id,
//         data: {
//           fulfillment_status: FulfillmentStatus.Confirmed,
//           fulfillment_status_history: updatedFulfillmentHistory,
//         },
//       },
//     })
//   }

//   export function useInitiateDeliveryOrder(
//     id: string,
//     updatedFulfillmentHistory: ComponentTicketsFulfillmentStatusHistoryInput[],
//     options?: MutationHookOptions<UpdateTicketOrderMutation>
//   ) {
//     return useUpdateTicketOrderMutation({
//       ...options,
//       variables: {
//         id,
//         data: {
//           fulfillment_status: FulfillmentStatus.DeliveryInitiated,
//           fulfillment_status_history: updatedFulfillmentHistory,
//         },
//       },
//     })
//   }

//   export function useAcceptDeliveryOrder(
//     id: string,
//     updatedFulfillmentHistory: ComponentTicketsFulfillmentStatusHistoryInput[],
//     options?: MutationHookOptions<UpdateTicketOrderMutation>
//   ) {
//     return useUpdateTicketOrderMutation({
//       ...options,
//       variables: {
//         id,
//         data: {
//           fulfillment_status: FulfillmentStatus.DeliveryAccepted,
//           fulfillment_status_history: updatedFulfillmentHistory,
//         },
//       },
//     })
//   }

//   export function useFulfillOrder(
//     id: string,
//     options?: MutationHookOptions<UpdateTicketOrderMutation>
//   ) {
//     return useUpdateTicketOrderMutation({
//       ...options,
//       variables: {
//         id: id,
//         data: {
//           fulfillment_status: FulfillmentStatus.Fulfilled,
//         },
//       },
//       // update: (cache, { data }) => {
//       //   const updatedOrder = data?.updateTicketorder?.ticketorder
//       //   const existingsOrders = cache.readQuery<TicketOrdersQuery>({
//       //     query: TicketOrdersDocument,
//       //   })
//       //   if (updatedOrder && existingsOrders?.ticketorders) {
//       //     cache.writeQuery<TicketOrdersQuery>({
//       //       query: TicketOrdersDocument,
//       //       data: {
//       //         ticketorders: [...existingsOrders.ticketorders, updatedOrder],
//       //       },
//       //     })
//       //   }
//       // },
//     })
//   }
