import React from 'react'
import {
  Input as ChakraInput,
  InputProps,
  useColorModeValue,
} from '@chakra-ui/react'

const Input: React.FC<InputProps> = props => {
  const focusBorderColor = useColorModeValue('brand.400', 'brand.300')

  return (
    <ChakraInput
      rounded="lg"
      variant="filled"
      focusBorderColor={focusBorderColor}
      {...props}
    />
  )
}

export default Input
