import React, { useLayoutEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
// import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5map from '@amcharts/amcharts5/map'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5geodata_usaLow from '@amcharts/amcharts5-geodata/usaLow'
import { useColorModeValue } from '@chakra-ui/react'

import { IUserData } from '~hooks/queries/users'

interface Props {
  users: IUserData[]
  data: { id: string; value: number }[]
}

const UsMap: React.FC<Props> = ({ users, data }) => {
  const polygonBgColor = useColorModeValue('#000000', '#000000')

  useLayoutEffect(() => {
    if (data.length === 0) return
    // let regionalSeries = {}
    // let currentSeries

    let root = am5.Root.new('usmap')
    root.setThemes([am5themes_Animated.new(root)])

    let map = root.container.children.push(
      am5map.MapChart.new(root, {
        // panX: 'rotateX',
        // panY: 'rotateY',
        wheelY: 'none',
        wheelable: false,
        centerMapOnZoomOut: true,
        projection: am5map.geoAlbersUsa(),
      })
    )

    let polygonSeries = map.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_usaLow,
        valueField: 'value',
        calculateAggregates: true,
      })
    )

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}:\n[bold]{value} users[/]',
      // tooltipText: '{name}',
    })

    polygonSeries.mapPolygons.template.states.create('hover', {
      // fill: am5.color(0x853493),
      fill: am5.color(polygonBgColor),
    })

    polygonSeries.set('heatRules', [
      {
        target: polygonSeries.mapPolygons.template,
        dataField: 'value',
        min: am5.color(0xffe2ef),
        max: am5.color(0x7e002c),
        key: 'fill',
      },
    ])

    polygonSeries.data.setAll(data)

    const zoomOut = root.tooltipContainer.children.push(
      am5.Button.new(root, {
        x: am5.p100,
        y: 0,
        centerX: am5.p100,
        centerY: 0,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 12,
        paddingRight: 12,
        cursorOverStyle: 'pointer',
        dx: -20,
        dy: 20,
        themeTags: ['zoom'],
        icon: am5.Graphics.new(root, {
          themeTags: ['button', 'icon'],
          strokeOpacity: 0.8,
          draw: function (display) {
            display.moveTo(0, 0)
            display.lineTo(12, 0)
          },
        }),
      })
    )

    zoomOut.events.on('click', () => {
      map.goHome()
      zoomOut.hide()
    })

    zoomOut.hide()

    createSeries()

    // Finds polygon in series by its id
    function getPolygon(id: string): am5map.MapPolygon | undefined {
      // console.log({ id })
      let found: am5map.MapPolygon | undefined
      polygonSeries.mapPolygons.each(polygon => {
        console.log({ polygon })
        if (polygon.dataItem?.get('id') === id) {
          found = polygon
        }
      })
      return found
    }

    function createSeries() {
      const states: any[] = []

      am5.array.each(data, d => {
        const statePolygon = getPolygon(d.id)

        if (statePolygon) {
          const centroid = statePolygon.visualCentroid()
          console.log({
            statePolygon,
            centroid,
            name: statePolygon.dataItem?.dataContext.name,
          })

          if (d.value > 0) {
            states.push({
              ...d,
              ...statePolygon.dataItem?.dataContext,
              type: 'state',
              geometry: {
                type: 'Point',
                coordinates: [centroid.longitude, centroid.latitude],
              },
            })
          }
        } else {
          return
        }
      })

      const pointSeries = map.series.push(
        am5map.MapPointSeries.new(root, {
          valueField: 'value',
          calculateAggregates: true,
        })
      )

      // Add bullet
      let circleTemplate = am5.Template.new(root)

      pointSeries.bullets.push(function () {
        const container = am5.Container.new(root, {})

        const circle = container.children.push(
          am5.Circle.new(
            root,
            {
              radius: 10,
              fill: am5.color(0x000000),
              fillOpacity: 0.7,
              cursorOverStyle: 'pointer',
              tooltipText: '{name}:\n[bold]{value} users[/]',
            },
            circleTemplate
          )
        )

        const label = container.children.push(
          am5.Label.new(root, {
            text: '{value}',
            fontSize: 14,
            fill: am5.color(0xffffff),
            populateText: true,
            centerX: am5.p50,
            centerY: am5.p50,
            textAlign: 'center',
          })
        )

        circle.events.on('click', event => {
          const data = event.target.dataItem?.dataContext
          console.log({ data })
          const statePolygon = getPolygon(data.id)
          polygonSeries.zoomToDataItem(statePolygon?.dataItem)

          zoomOut.show()
        })

        return am5.Bullet.new(root, {
          sprite: container,
        })
      })

      // Add heat rule for circles
      pointSeries.set('heatRules', [
        {
          target: circleTemplate,
          dataField: 'value',
          min: 10,
          max: 30,
          key: 'radius',
        },
      ])

      pointSeries.data.setAll(states)
    }

    return () => {
      root.dispose()
      map.dispose()
      polygonSeries.dispose()
    }
  }, [data, polygonBgColor])

  return <div id="usmap" style={{ width: '100%', height: '100%' }} />
}

export default UsMap
