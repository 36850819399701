import React, { useState, useMemo } from 'react'
import { AspectRatio, Box, Text, Select, SimpleGrid } from '@chakra-ui/react'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import Input from '~components/shared/input'
import UsersMap from '~components/pages/analytics/users-map'
import UsersChart from '~components/pages/analytics/users-chart'
import UsersTable from '~components/pages/analytics/users-table'
// import DrillDownMap from '~components/shared/amcharts/drill-down-map'
import { useMapData, useDebounce } from '~hooks/index'
import { abbrState } from '~utils/state'

const MapPage: React.FC = () => {
  const [searchText, setSearchText] = useState('')
  const [membershipOption, setMembershipOption] = useState('')
  const [timelineOption, setTimelineOption] = useState('')
  const { data } = useMapData()

  const debouncedSearchText = useDebounce(searchText, 300)

  const users = useMemo(
    () =>
      (data || [])
        .map(u => ({
          ...u,
          membership: u.membership === 'Founding' ? 'Founder' : u.membership,
        }))
        .filter(u => !!u.state && !!u.membership),
    [data]
  )

  const memberships = useMemo(
    () =>
      (
        users.reduce((res: string[], current) => {
          if (!current.membership || res.includes(current.membership))
            return res
          else return [...res, current.membership]
        }, []) || []
      )
        .filter(m => m.length < 20)
        .sort((a, b) => a.length - b.length),
    [users]
  )

  const filteredUsers = useMemo(() => {
    const query: string = debouncedSearchText.toLowerCase()

    return users
      .filter(
        u =>
          u.email?.toLowerCase().includes(query) ||
          query.split(' ').some(q => u.first_name?.toLowerCase().includes(q)) ||
          query.split(' ').some(q => u.last_name?.toLowerCase().includes(q))
      )
      .filter(u => membershipOption === '' || u.membership === membershipOption)
  }, [users, membershipOption, debouncedSearchText])

  const chartData = useMemo(
    () =>
      filteredUsers.reduce((res, current) => {
        const currentYear = res.find(
          s => s.year === new Date(current.created_at).getFullYear()
        )
        return [
          ...res.filter(
            s => s.year !== new Date(current.created_at).getFullYear()
          ),
          {
            year: new Date(current.created_at).getFullYear(),
            total: currentYear?.total ? currentYear.total + 1 : 1,
            ...memberships.reduce((resMembership, currentMembership) => {
              if (currentYear) {
                return {
                  ...resMembership,
                  [currentMembership.toLowerCase()]:
                    currentMembership.toLowerCase() ===
                    current.membership?.toLowerCase()
                      ? currentYear[currentMembership.toLowerCase()] + 1
                      : currentYear[currentMembership.toLowerCase()],
                }
              }

              return {
                ...resMembership,
                [currentMembership.toLowerCase()]:
                  currentMembership.toLowerCase() ===
                  current.membership?.toLowerCase()
                    ? 1
                    : 0,
              }
            }, {}),
          },
        ]
      }, [] as { [key: string]: number; year: number }[]),
    [filteredUsers]
  )

  const mapData = useMemo(
    () =>
      filteredUsers
        .map(user => ({
          ...user,
          state_id: abbrState(user.state || '', 'abbr'),
        }))
        .reduce(
          (res, current) => {
            if (!current.state_id) return res

            // if (res.filter(s => s.id === current.state_id).length === 0) {
            //   res.push({ id: `US-${current.state_id}`, value: 1 })
            //   return res
            // }

            const currentValue = res.find(
              s => s.id.split('-')[1] === current.state_id
            )?.value

            // if (currentValue) {
            return [
              ...res
                .filter(s => s.id.split('-')[1] !== current.state_id)
                .concat([
                  {
                    id: `US-${current.state_id}`,
                    value: currentValue ? currentValue + 1 : 1,
                  },
                ]),
            ]
            // } else {
            //   res.push({ id: `US-${current.state_id}`, value: 1 })
            //   return res
            // }
          },
          [
            { id: 'US-AL', value: 0 },
            { id: 'US-AK', value: 0 },
            { id: 'US-AZ', value: 0 },
            { id: 'US-AR', value: 0 },
            { id: 'US-CA', value: 0 },
            { id: 'US-CO', value: 0 },
            { id: 'US-CT', value: 0 },
            { id: 'US-DE', value: 0 },
            { id: 'US-FL', value: 0 },
            { id: 'US-GA', value: 0 },
            { id: 'US-HI', value: 0 },
            { id: 'US-ID', value: 0 },
            { id: 'US-IL', value: 0 },
            { id: 'US-IN', value: 0 },
            { id: 'US-IA', value: 0 },
            { id: 'US-KS', value: 0 },
            { id: 'US-KY', value: 0 },
            { id: 'US-LA', value: 0 },
            { id: 'US-ME', value: 0 },
            { id: 'US-MD', value: 0 },
            { id: 'US-MA', value: 0 },
            { id: 'US-MI', value: 0 },
            { id: 'US-MN', value: 0 },
            { id: 'US-MS', value: 0 },
            { id: 'US-MO', value: 0 },
            { id: 'US-MT', value: 0 },
            { id: 'US-NE', value: 0 },
            { id: 'US-NV', value: 0 },
            { id: 'US-NH', value: 0 },
            { id: 'US-NJ', value: 0 },
            { id: 'US-NM', value: 0 },
            { id: 'US-NY', value: 0 },
            { id: 'US-NC', value: 0 },
            { id: 'US-ND', value: 0 },
            { id: 'US-OH', value: 0 },
            { id: 'US-OK', value: 0 },
            { id: 'US-OR', value: 0 },
            { id: 'US-PA', value: 0 },
            { id: 'US-RI', value: 0 },
            { id: 'US-SC', value: 0 },
            { id: 'US-SD', value: 0 },
            { id: 'US-TN', value: 0 },
            { id: 'US-TX', value: 0 },
            { id: 'US-UT', value: 0 },
            { id: 'US-VT', value: 0 },
            { id: 'US-VA', value: 0 },
            { id: 'US-WA', value: 0 },
            { id: 'US-WV', value: 0 },
            { id: 'US-WI', value: 0 },
            { id: 'US-WY', value: 0 },
          ]
        ),
    [filteredUsers]
  )

  return (
    <>
      <Seo title="Map" />
      <Heading>Users</Heading>
      <SimpleGrid columns={[1, 3]} spacing={[2, 4]}>
        <Box>
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            Search
          </Text>
          <Input
            placeholder="Search by name or email"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </Box>
        <Box>
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            Membership
          </Text>
          <Select
            rounded="lg"
            variant="filled"
            focusBorderColor="brand.300"
            placeholder="All memberships"
            value={membershipOption}
            onChange={v => setMembershipOption(v.target.value)}
          >
            {memberships.map(m => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text fontSize="sm" fontWeight="bold" mb={1}>
            Timeline
          </Text>
          <Select
            rounded="lg"
            variant="filled"
            focusBorderColor="brand.300"
            placeholder="All-time"
            value={timelineOption}
            onChange={v => setTimelineOption(v.target.value)}
          >
            <option>Last 3 months</option>
            <option>Last 6 months</option>
            <option>This year</option>
            <option>Last year</option>
          </Select>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        display={['none', 'grid']}
        columns={[1, 1, 1, 1, 2]}
        spacing={[2, 4]}
        mt={8}
        mb={12}
      >
        <AspectRatio ratio={16 / 9} maxH="600px">
          <Box rounded="xl" overflow="hidden">
            <UsersMap users={filteredUsers} data={mapData} />
          </Box>
        </AspectRatio>
        <AspectRatio ratio={16 / 9} maxH="600px">
          <Box rounded="xl" overflow="hidden">
            {/* <DrillDownMap /> */}
            <UsersChart data={chartData} />
          </Box>
        </AspectRatio>
      </SimpleGrid>
      <Box>
        <UsersTable users={filteredUsers} searchText={debouncedSearchText} />
      </Box>
    </>
  )
}

export default MapPage
