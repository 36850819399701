import React from 'react'
import { format } from 'date-fns'
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import Highlighter from 'react-highlight-words'

import { IUserData } from '~hooks/queries/users'

interface Props {
  users: IUserData[]
  searchText: string
}

const UsersTable: React.FC<Props> = ({ users, searchText }) => {
  return (
    <Box overflow="auto">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>User</Th>
            <Th>Location</Th>
            <Th>Membership</Th>
            <Th>Joined</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((u, i) => (
            <Tr key={u.id}>
              <Td>
                <Text>{i + 1}</Text>
              </Td>
              <Td>
                <Box my={1}>
                  <Text fontWeight="bold">
                    <Highlighter
                      searchWords={searchText.split(' ')}
                      textToHighlight={`${u.first_name} ${u.last_name}`}
                    />
                  </Text>
                  <Text fontSize="xs">
                    <Highlighter
                      searchWords={[searchText]}
                      textToHighlight={u.email}
                    />
                  </Text>
                </Box>
              </Td>
              <Td>
                {u.city && u.state && <Text>{`${u.city}, ${u.state}`}</Text>}
              </Td>
              <Td>
                <Text>{u.membership}</Text>
              </Td>
              <Td>
                <Text isTruncated>
                  {format(new Date(u.created_at), 'MMM d, yyyy')}
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export default UsersTable
